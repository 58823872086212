import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import swapContractABI from './swapContractABI.json';
import ERC20ABI from './ERC20ABI.json';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import Modals from "./components/modals";
import axios from 'axios';


function App() {

  const [swapContract, setSwapContract] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [direction, setDirection] = useState(false);
  const [tokenContract, setTokenContract] = useState(null);
  const [usdtContractBalance, setUsdtContractBalance] = useState(0);
  const [tokenContractBalance, setTokenContractBalance] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [usdtAmount, setUsdtAmount] = useState(0);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [tokenDecimals, setTokenDecimals] = useState(18);
  const [title, setTitle] = useState(null);
  const [swapButtonText, setSwapButtonText] = useState("Swap");
  const [swapFee, setSwapFee] = useState(0);
  const [minSwap, setMinSwap] = useState(0);
  const [adminWallet, setAdminWallet] = useState("0x");
  
  const usdtAddress ='0x55d398326f99059fF775485246999027B3197955';

  const getContractBalances = async (swpContract) => {
    try {
      // Initialize ethers with BSC provider
      const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
      
      // Create a contract instance
      const contract = new ethers.Contract(swpContract, swapContractABI, provider);
      console.log(swpContract);

      // Fetch USDT balance
      const usdtContractBal = await contract.usdtBalance();
      setUsdtContractBalance(ethers.utils.formatUnits(usdtContractBal, 18));

      // Fetch USDT balance
      const tokenContractBal = await contract.tokenBalance();
      setTokenContractBalance(ethers.utils.formatUnits(tokenContractBal, tokenDecimals));

    } catch (error) {
      console.error('Error getting balance:', error);
    }
  }
  
  const handleUsdtAmountChange = (event) => {
    setUsdtAmount(event.target.value);
    if(event.target.value > 0){
      setTokenAmount(event.target.value);
    }
  };

  const handleTokenAmountChange = (event) => {
    setTokenAmount(event.target.value);
    if(event.target.value > 0){
      setUsdtAmount(event.target.value);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('data.json');
      setSwapContract(response.data[0].value);
      setTokenContract(response.data[1].value);
      setTokenSymbol(response.data[2].value);
      setTokenDecimals(response.data[3].value);
      setTitle(response.data[4].value);
      setSwapFee(response.data[5].value);
      setMinSwap(response.data[6].value);
      setAdminWallet(response.data[7].value);
      getContractBalances(response.data[0].value);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    checkWalletIsConnected();
    fetchData();

  }, []);

  const handelDirection = () => {
    setDirection(!direction);
    setTokenAmount(0);
    setUsdtAmount(0);
  }

  async function addToken() {
    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Currently only supports ERC20 tokens
          options: {
            address: tokenContract, // The token's contract address
            symbol: tokenSymbol,   // A string symbol of the token
            decimals: tokenDecimals,    // The number of decimals the token uses
            image: window.location.href + '/img/token-logo.png',  // A string url of the token logo
          },
        },
      });
  
      if (wasAdded) {
        console.log('Token was added successfully!');
      } else {
        console.log('Token was not added.');
      }
    } catch (error) {
      console.error(error);
    }
  }

  const bscUrl = 'https://bscscan.com/token/' + tokenContract;
  const usdtUrl = 'https://bscscan.com/token/' + usdtAddress;


  const checkWalletIsConnected = async() => {
    const detectedProvider = await detectEthereumProvider();
    if(!detectedProvider){
      console.log("Metamask NOT Installed");
      return;
    }else{
      console.log("Metamask Installed");
    }
   }

  const connectWalletHandler = async() => { 
    const detectedProvider = await detectEthereumProvider();
    if(!detectedProvider){
      alert("Please Install Metamask!");
    }

    try{
      const accounts = await detectedProvider.request({method: 'eth_requestAccounts'});
      console.log("Found an account :", accounts[0]);
      setCurrentAccount(accounts[0]);
    }catch (err){
      console.log(err);
    }
  }

  const usdtWithdrawHandler = async() => {
    try{
      const detectedProvider = await detectEthereumProvider();

      if(detectedProvider){
        const provider = new ethers.providers.Web3Provider(detectedProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(swapContract, swapContractABI, signer);

        console.log("Intialize withdraw");
        let withdrawRes = await contract.withdrawUSDT(adminWallet);
        
        if(withdrawRes){
          alert("Congratulations, you will receive your USDT very soon");
        }else{
          alert("Something wrong, Only the admin wallet who can withdraw.");
        }
          }
        }catch(err){
        alert("Something wrong, Only the admin wallet who can withdraw.");
          console.log(err);
        }
  }

  const tokenWithdrawHandler = async() => {
    try{
      const detectedProvider = await detectEthereumProvider();

      if(detectedProvider){
        const provider = new ethers.providers.Web3Provider(detectedProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(swapContract, swapContractABI, signer);

        console.log("Intialize withdraw");
        let withdrawRes = await contract.withdrawTokens(adminWallet);
        
        if(withdrawRes){
          alert("Congratulations, you will receive your tokens very soon");
        }else{
          alert("Something wrong, Only the admin wallet who can withdraw.");
        }
          }
        }catch(err){
        alert("Something wrong, Only the admin wallet who can withdraw.");
          console.log(err);
        }
  }

  const swapTokens = async() => {
    if (usdtAmount < minSwap && !direction) {
      alert("Enter at least " + minSwap + " USDT");
      return;
    }
    if (tokenAmount < minSwap && direction) {
      alert("Enter at least " + minSwap + " " + tokenSymbol);
      return;
    }
      const detectedProvider = await detectEthereumProvider();
      const chainId = await detectedProvider.request({ method: 'eth_chainId' });
      if(parseInt(chainId, 16) !== 56){
        alert("Please Switch MetaMask to BNB Smart Chain");
        await detectedProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x38' }],
        });
        return;
      };

      if(detectedProvider){
        const provider = new ethers.providers.Web3Provider(detectedProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(swapContract, swapContractABI, signer);
        const tokenContractApproval = new ethers.Contract(tokenContract, ERC20ABI, signer);
        const usdtContractApproval = new ethers.Contract(usdtAddress, ERC20ABI, signer);


        if(!direction){
          // Get the USDT balance
          const usdtBal = new ethers.Contract(usdtAddress, ERC20ABI, provider);
          const balance = await usdtBal.balanceOf(currentAccount);
          if(ethers.utils.formatUnits(balance, 18) < parseInt(usdtAmount,10)){
            alert('Not enough USDT balance in your wallet');
            return;
          };
          try {
            // Approve the swapContract to spend tokens
            console.log(usdtAmount);
            console.log(ethers.utils.parseUnits(usdtAmount, 18));
            const approvalTx = await usdtContractApproval.approve(swapContract, ethers.utils.parseUnits(usdtAmount, 18));
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await approvalTx.wait();
            console.log('Approval confirmed');
            //alert("approval confirmed");

            // Swap USDT for Tokens
            const tx = await contract.swapUsdt(
              ethers.utils.parseUnits(usdtAmount, 18),
              { value: swapFee}
            );
            console.log('Transaction sent:', tx);
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await tx.wait();
            console.log('Transaction confirmed:', tx);
            setSwapButtonText('Swap');
            setIsLoading(false);
            alert('Congratulations, Swap transaction confirmed');
          } catch (error) {
            setSwapButtonText('Swap');
            setIsLoading(false);
            console.error('Swap failed', error);
            alert('Swap failed');
          }
        }else{
          // Get the token balance
          const contractBal = new ethers.Contract(tokenContract, ERC20ABI, provider);
          const tokenBal = await contractBal.balanceOf(currentAccount);
          if(ethers.utils.formatUnits(tokenBal, tokenDecimals) < parseInt(tokenAmount, 10)){
            alert('Not enough ' + tokenSymbol + ' tokens balance in your wallet');
            return;
          };
          try {
            // Approve the swapContract to spend tokens
            const approvalTx = await tokenContractApproval.approve(swapContract, ethers.utils.parseUnits(tokenAmount, tokenDecimals));
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            await approvalTx.wait();
            console.log('Approval confirmed');
            //alert("approval confirmed");

            // Swap tokens for USDT
            const tx = await contract.swapToken(
              ethers.utils.parseUnits(tokenAmount, tokenDecimals),
              {value: swapFee}
            );
            setSwapButtonText('Please Wait...');
            setIsLoading(true);
            console.log('Transaction sent:', tx);
            await tx.wait();
            console.log('Transaction confirmed:', tx);
            setSwapButtonText('Swap');
            setIsLoading(false);
            alert('Congratulations, Swap transaction confirmed');
          } catch (error) {
            setSwapButtonText('Swap');
            console.error('Swap failed', error);
            setIsLoading(false);
            alert('Swap failed');
          }
        }

    }
  }

  const connectWalletButton = () => {
    return (
      <button onClick={connectWalletHandler} className="curr-btn btn-connect">Connect Wallet</button>
    )
  }

  const swapButton = () => {
    return (
      <button onClick={swapTokens} className="curr-btn btn-connect">{swapButtonText}</button>
    )
  }

  function isAdmin(){
    if(adminWallet === null || currentAccount === null){
      return false;
    }
    if(adminWallet.toLowerCase() === currentAccount.toLowerCase()){
      return true;
    }
    return false;
  }


  return (
    <>
      <nav className="navbar navbar-expand-sm flex-sm-nowrap flex-wrap">
        <div className="container-fluid">
          <button
            className="navbar-toggler flex-grow-sm-1 flex-grow-0 me-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar5"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <span className="navbar-brand">
            <a href="/#">
              <img src="img/logo.png" height={50} width={200} alt="Add to MetaMask"/>
            </a>
          </span>

          <div className="navbar-collapse collapse" id="navbar5">

            <div className="d-flex w-100 justify-content-end">
              <div className="border-box-s d-flex flex-row align-item-center py-2">
                <div className="border-box-s d-flex">

                        <div className="textfirst">
                          <div className="d-flex gap-2 align-item-center text-white">
                            <img
                              src="./img/bnb-logo.svg"
                              alt=""
                              width="20"
                              height="20"
                            />
                            <div>BNB Smart Chain</div>
                          </div>
                        </div>

                    
                </div>
              </div>
            </div>
          </div>

        </div>
      </nav>


              <div className="card-st">
                <div className="card-d">
                
                    <main className="card-d-bg">
                      <div className="border-boxing flex-adjust-d space-s-between ma-color px-2 pb-1">
                        <div className="d-flex gap-2">
                          <div className="s-color pad-link"><h4>{title}</h4></div>
                        </div>

                        <div className="border-boxing flex-adjust-d width-fit-d justify-content-end">
                          <div className="position-relative">
                            <div className="dropdown">
                              <button
                                className="btn border-unset-d p-0"
                                type="button"
                                onClick={addToken}
                              >
                                <div className="border-boxing flex-adjust-d svg-pad pe-0">
                                  <img src="img/metamask.png" height={24} width={24} alt="Add to MetaMask"/>
                                </div>
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{display:'flex', flexDirection: `${direction ? 'column-reverse' : 'column'}`}}>
                        <div className="swap-card" id="from_token_select">
                          <div className="flex-wrap-d">
                            <div className="input-contain">
                              <div className="flex-ne">
                                <input
                                  className="input-swap-d input-d-swp"
                                  type="text"
                                  placeholder="0"
                                  value={direction? parseFloat(usdtAmount) : usdtAmount} 
                                  onChange={handleUsdtAmountChange}
                                />
                                <div className="border-boxing flex-adjust-d width-fit-d">
                                  <div className="position-relative d-flex me-1">
                                  <a href={usdtUrl} target='_blank' rel="noreferrer"><img src="img/usdt.png" height={32} width={32} alt="USDT Contract"/></a>
                                  </div>
                                  <span className="currency-text">USDT</span>
                                </div>
                              </div>
                              <div className="amount-div amt-d">
                                <div className="border-boxing flex-adjust-d space-s-between">
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="arrow-div" onClick={handelDirection}>
                          <button
                            // onClick={this.switchTokens}
                            id="swap_token_address"
                            className="arrow-swap"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#5D6785"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <polyline points="19 12 12 19 5 12"></polyline>
                            </svg>
                          </button>
                        </div>

                        <div className="swap-card" id="to_token_select">
                            <div className="flex-wrap-d">
                              <div className="input-contain">
                                <div className="flex-ne">
                                  <input
                                    className="input-swap-d input-d-swp"
                                    placeholder="0"
                                    value={direction? tokenAmount : parseFloat(tokenAmount)} 
                                    onChange={handleTokenAmountChange}
                                  />
                                  <div className="border-boxing flex-adjust-d width-fit-d">
                                  <div className="position-relative d-flex me-1">
                                  <a href={bscUrl} target='_blank' rel="noreferrer"><img src="img/token-logo.png" height={32} width={32} alt="Add to MetaMask"/></a>
                                    </div>
                                    <span className="currency-text">{tokenSymbol}</span>
                                  </div>
                                </div>
                                <div className="amount-div amt-d">
                                  <div className="border-boxing flex-adjust-d space-s-between">
                                    <div className=" "></div>
                                    <span></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div className="input-div-s">
                        <div>
                          
                        </div>

                        <div className="py-2">
                          <div className="accordion accordion-st" id="details">
                            <div className="accordion-item accordion-itm">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button collapsed text-white accord-btn"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  Pool Balance & Details
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                data-bs-parent="#details"
                              >
                                <div className="accordion-body pt-0">
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">USDT Balance:</p>
                                    <p className="m-0 text-grey">{usdtContractBalance}</p>
                                  </div>
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">{tokenSymbol} Balance:</p>
                                    <p className="m-0 text-grey">{tokenContractBalance}</p>
                                  </div>
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Swap Rate:</p>
                                    <p className="m-0 text-grey">1 : 1</p>
                                  </div>
                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Minmum Swap:</p>
                                    <p className="m-0 text-grey">{minSwap} USDT</p>
                                  </div>
                                  <hr className="my-1 border-s-white" />

                                  <div className="d-flex py-2 justify-space-between">
                                    <p className="m-0 text-white">Swap Fee</p>
                                    <p className="m-0 text-grey">{ethers.utils.formatEther(swapFee)} BNB</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {currentAccount ? swapButton() : connectWalletButton()}
                          {isAdmin() && 
                            <div>
                              <br />
                              <button onClick={usdtWithdrawHandler} className="curr-btn btn-connect">Withdraw USDT</button>
                              <br />
                              <button onClick={tokenWithdrawHandler} className="curr-btn btn-connect">Withdraw {tokenSymbol}</button>
                            </div>
                          }
                        </div>
                      </div>
                    </main> 
                        
                </div>
              </div>

      <Modals />
    </>
  );
}

export default App;
